export const listProjects = [
  {
    node: {
      id: "realtime-agents",
      frontmatter: {
        title: "Realtime Agents",
        client: "openAI hackathon",
        company: null,
        order: 2100,
        path: "/projects/realtime-agents",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["GenAI", "NLP", "react", "realtime"],
        cover_image: "/assets/projectDetail/realtime-agents/thumbnail.png",
      },
      fields: {
        slug: "/projects/realtime-agents/",
      },
    },
  },

  {
    node: {
      id: "stylist",
      frontmatter: {
        title: "AI Stylist",
        client: "hackathon",
        company: null,
        order: 2000,
        path: "/projects/ai-stylist",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["GenAI", "NLP", "react"],
        cover_image: "/assets/projectDetail/stylist/thumbnail.png",
      },
      fields: {
        slug: "/projects/ai-stylist/",
      },
    },
  },

  {
    node: {
      id: "wish-well",
      frontmatter: {
        title: "Wish-Well.art",
        client: "art installation",
        company: null,
        order: 1900,
        path: "/projects/wish-well",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["Art", "PixiJS", "TypeScript", "WebGL"],
        cover_image: "/assets/projectDetail/wish-well/thumbnail.png",
      },
      fields: {
        slug: "/projects/wish-well/",
      },
    },
  },

  {
    node: {
      id: "p6",
      frontmatter: {
        title: "AI Focus Group",
        client: "hackathon",
        company: null,
        order: 1800,
        path: "/projects/ai-focus-group",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["GenAI", "NLP", "react"],
        cover_image: "/assets/projectDetail/focus-group/thumbnail.png",
      },
      fields: {
        slug: "/projects/ai-focus-group/",
      },
    },
  },

  {
    node: {
      id: "p7",
      frontmatter: {
        title: "AIME AI procurement assistant",
        client: "Tealbook",
        company: null,
        order: 1700,
        path: "/projects/aime",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["GenAI", "NLP", "react"],
        cover_image: "/assets/projectDetail/aime/thumbnail.png",
      },
      fields: {
        slug: "/projects/aime/",
      },
    },
  },

  {
    node: {
      id: "p8",
      frontmatter: {
        title: "SugarGraph Video Fact Checking System",
        client: "hackathon",
        company: null,
        order: 1600,
        path: "/projects/sugar-graph",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["GenAI", "NLP", "react"],
        cover_image: "/assets/projectDetail/sugargraph/thumbnail.png",
      },
      fields: {
        slug: "/projects/sugar-graph/",
      },
    },
  },

  {
    node: {
      id: "p6",
      frontmatter: {
        title: "AI Collaboration",
        client: "CXSF",
        company: null,
        order: 1500,
        path: "/projects/ai-collab",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["chatGPT", "NLP", "GenAI", "Art", "NFT"],
        cover_image: "/assets/projectDetail/ai-collab/ai-collab-thumb.png",
      },
      fields: {
        slug: "/projects/ai-collab/",
      },
    },
  },

  {
    node: {
      id: "p6",
      frontmatter: {
        title: "Generative Adventures",
        client: "BlueSky",
        company: null,
        order: 1400,
        path: "/projects/genstory",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["chatGPT", "Games", "GenAI", "Art", "Games"],
        cover_image: "/assets/projectDetail/genstory/thumb.png",
      },
      fields: {
        slug: "/projects/genstory/",
      },
    },
  },

  {
    node: {
      id: "p5",
      frontmatter: {
        title: "RevelUp",
        client: "Revel.xyz",
        company: null,
        order: 1300,
        path: "/projects/revelup",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["NFT", "ERC721", "Solidity", "GenAI", "Games"],
        cover_image: "/assets/projectDetail/revelup/thumb.jpg",
      },
      fields: {
        slug: "/projects/revelup/",
      },
    },
  },

  {
    node: {
      id: "p4",
      frontmatter: {
        title: "DreamEngine",
        client: "ArtBlocks",
        company: null,
        order: 1200,
        path: "/projects/dreamEngine",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["NFT", "ERC721", "Solidity", "Art", "Games"],
        cover_image: "/assets/projectDetail/dreamEngine/dream-engine-sm.gif",
      },
      fields: {
        slug: "/projects/dreamengine/",
      },
    },
  },

  {
    node: {
      id: "p3",
      frontmatter: {
        title: "dCards",
        client: "NFT Greeting card",
        company: null,
        order: 1200,
        path: "/projects/dcards",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["NFT", "ERC721", "Solidity", "Art"],
        cover_image: "/assets/projectDetail/dCards/icon.png",
      },
      fields: {
        slug: "/projects/zerocontact/",
      },
    },
  },

  {
    node: {
      id: "p2",
      frontmatter: {
        title: "LayerC",
        client: "Discord token gateway",
        company: null,
        order: 1200,
        path: "/projects/layerC",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["NFT", "ERC721", "Solidity", "Chatbots"],
        cover_image: "/assets/projectDetail/layerC/icon.png",
      },
      fields: {
        slug: "/projects/zerocontact/",
      },
    },
  },

  {
    node: {
      id: "ac7fe86d-ea63-5922-b150-ba2f03dc0285",
      frontmatter: {
        title: "ZeroContact",
        client: "NFT Puzzle",
        company: null,
        order: 1200,
        path: "/projects/zerocontact",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["NFT", "ERC721", "Solidity", "Games"],
        cover_image: "/assets/projects/zcon-map.jpg",
      },
      fields: {
        slug: "/projects/zerocontact/",
      },
    },
  },

  {
    node: {
      id: "75afe77a-45a5-5bb5-87a3-c470b0dc3b62",
      frontmatter: {
        title: "Grapheme",
        client: "NFT hackathon",
        company: null,
        order: 1100,
        path: "/projects/grapheme",
        sm: 3,
        xs: 3,
        where: null,
        from: null,
        until: null,
        tags: ["NFT", "ERC721", "Solidity", "Art"],
        cover_image: "/assets/projectDetail/grapheme/icon.png",
      },
      fields: {
        slug: "/projects/grapheme/",
      },
    },
  },
  {
    node: {
      id: "a93c396b-3f7b-57d2-b73b-d71f3ba9f138",
      frontmatter: {
        title: "AI Architect",
        client: "Google",
        company: "Google",
        order: 1000,
        path: "/projects/ccai",
        sm: 6,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "sdk",
          "Python",
          "TypeScript",
          "NLP",
          "GenAI",
          "DialogFlow",
          "BigQuery",
          "Google Cloud",
          "TensorFlow",
          "MachineLearning",
          "Chatbots",
        ],
        cover_image: "/assets/projects/ccai-flow.png",
      },
      fields: {
        slug: "/projects/ccai/",
      },
    },
  },
  {
    node: {
      id: "b08c76cf-f21d-5321-9a38-9b4d4195d5d5",
      frontmatter: {
        title: "TalkingBlocks NFT Art",
        client: "personal",
        company: "ArtBlocks",
        order: 900,
        path: "/projects/blocks",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["NodeJS", "TypeScript", "p5.js", "NFT", "Art"],
        cover_image: "/assets/projects/blocks.png",
      },
      fields: {
        slug: "/projects/blocks/",
      },
    },
  },
  {
    node: {
      id: "a5e553d5-75bc-594c-ae1d-4c57481432c1",
      frontmatter: {
        title: "True Riddles",
        client: "TEN",
        company: "TEN",
        order: 750,
        path: "/projects/riddles",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["Chatbots", "NodeJS", "TypeScript", "p5.js", "NFT", "Games"],
        cover_image: "/assets/projects/riddle-card.png",
      },
      fields: {
        slug: "/projects/riddles/",
      },
    },
  },
  {
    node: {
      id: "bd0ff9a1-c3be-51a8-b37b-53a502ac9743",
      frontmatter: {
        title: "TEN",
        client: "coop chat games for remote friends",
        company: "me",
        order: 700,
        path: "/projects/ten",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["Chatbots", "game design", "TypeScript", "JavaScript", "Games"],
        cover_image: "/assets/projects/duo-square.jpg",
      },
      fields: {
        slug: "/projects/ten/",
      },
    },
  },
  {
    node: {
      id: "0928c540-b6aa-5b00-80dc-1e8402c2b34b",
      frontmatter: {
        title: "BAP Bot Application Platform",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 500,
        path: "/projects/bap",
        sm: 6,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "Chatbots",
          "NodeJS",
          "RiBot",
          "Games",
          "ReactJS",
          "TypeScript",
          "MongoDB",
          "NLP",
        ],
        cover_image: "/assets/projects/bap-cover.png",
      },
      fields: {
        slug: "/projects/bap/",
      },
    },
  },
  {
    node: {
      id: "83fdc23e-eb44-5898-a890-5df31195221c",
      frontmatter: {
        title: "Hybrid Pipeline",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 400,
        path: "/projects/hybrid",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "Chatbots",
          "NodeJS",
          "ReactJS",
          "WeChat",
          "Tools",
          "MachineLearning",
          "TypeScript",
          "NLP",
        ],
        cover_image: "/assets/projects/hybrid-cover.jpg",
      },
      fields: {
        slug: "/projects/hybrid/",
      },
    },
  },
  {
    node: {
      id: "18cdbf40-5f2f-5eb4-b299-d28ea8973fcd",
      frontmatter: {
        title: "BizDev",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 300,
        path: "/projects/bizdev",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["BizDev", "Sales", "Presentations"],
        cover_image: "/assets/projects/bizdev-cover.jpg",
      },
      fields: {
        slug: "/projects/bizdev/",
      },
    },
  },
  {
    node: {
      id: "e6828357-b880-5bc7-b1f9-d8d2a8c16256",
      frontmatter: {
        title: "ConvoAI",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 300,
        path: "/projects/convoai",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["ML", "Demos", "Chatbots"],
        cover_image: "/assets/projects/grafr-3.png",
      },
      fields: {
        slug: "/projects/convoai/",
      },
    },
  },
  {
    node: {
      id: "0103f531-bbad-57b7-8ff6-56b02a691787",
      frontmatter: {
        title: "MiniBot Apps",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 300,
        path: "/projects/minibot-demos",
        sm: 6,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "Chatbots",
          "Authoring",
          "MiniBot",
          "JavaScript",
          "ZAT",
          "小程序",
          "微信",
          "MongoDB",
        ],
        cover_image: "/assets/projects/mb-demos.png",
      },
      fields: {
        slug: "/projects/minibot-demos/",
      },
    },
  },
  {
    node: {
      id: "43109256-4bde-5839-ac1d-b8201f705ec1",
      frontmatter: {
        title: "MiniBot Framework",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 300,
        path: "/projects/minibot",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: [
          "Authoring",
          "MiniBot",
          "JavaScript",
          "ZAT",
          "小程序",
          "微信",
          "MongoDB",
          "Chatbots",
        ],
        cover_image: "/assets/projects/mb-choices.jpg",
      },
      fields: {
        slug: "/projects/minibot/",
      },
    },
  },
  {
    node: {
      id: "44d33f2b-c237-5019-b774-7f73d6b648ca",
      frontmatter: {
        title: "NAP",
        client: "RIKAI Labs",
        company: "RIKAI Labs",
        order: 250,
        path: "/projects/nap",
        sm: 6,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "TypeScript",
          "VueJS",
          "FastText",
          "Element-ui",
          "JavaScript",
          "MongoDB",
          "NLP",
          "MachineLearning",
          "WordVectors",
          "Chatbots",
          "sdk",
        ],
        cover_image: "/assets/projects/nap-map.png",
      },
      fields: {
        slug: "/projects/nap/",
      },
    },
  },
  {
    node: {
      id: "87687717-86d7-55fe-9ddb-f47261fe8206",
      frontmatter: {
        title: "BabyBot",
        client: "MeadJohnson",
        company: "RIKAI Labs",
        order: 200,
        path: "/projects/babybot",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: [
          "NodeJS",
          "Metrics",
          "RiBot",
          "Python",
          "Gensim",
          "JavaScript",
          "NLP",
          "MachineLearning",
          "WordVectors",
          "Chatbots",
        ],
        cover_image: "/assets/projects/bbot-cover.jpg",
      },
      fields: {
        slug: "/projects/babybot/",
      },
    },
  },
  {
    node: {
      id: "3ab3e95e-faaf-595e-9c71-35d6422e916f",
      frontmatter: {
        title: "Beer Bot",
        client: "Budweiser",
        company: "RIKAI Labs",
        order: 200,
        path: "/projects/beerbot",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: [
          "NodeJS",
          "RiBot",
          "AB-testing",
          "Metrics",
          "WeChat",
          "Chatbots",
        ],
        cover_image: "/assets/projects/bud-cover.png",
      },
      fields: {
        slug: "/projects/beerbot/",
      },
    },
  },
  {
    node: {
      id: "c328555c-bd76-5dcf-9708-239aa56e376c",
      frontmatter: {
        title: "Cocktail Bot",
        client: "Pernod Ricard",
        company: "RIKAI Labs",
        order: 200,
        path: "/projects/askj",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: [
          // "NodeJS",
          // "RiBot",
          // "AB-testing",
          "Metrics",
          // "JavaScript",
          "Chatbots",
        ],
        cover_image: "/assets/projects/aj-cover.png",
      },
      fields: {
        slug: "/projects/askj/",
      },
    },
  },
  {
    node: {
      id: "1f87cc3d-c8ef-55fd-b93f-7fb258a332b4",
      frontmatter: {
        title: "Pharma Bot",
        client: "Sanofi",
        company: "RIKAI Labs",
        order: 200,
        path: "/projects/pharmabot",
        sm: 3,
        xs: 6,
        where: "shanghai and san francisco",
        from: "17/03/2019",
        until: "17/03/2019",
        tags: [
          "Chatbots",
          // "NodeJS",
          // "RiBot",
          // "NLG",
          "JavaScript",
          "MachineLearning",
          "WordVectors",
          // "MongoDB",
        ],
        cover_image: "/assets/projects/sanofi-award.jpg",
      },
      fields: {
        slug: "/projects/pharmabot/",
      },
    },
  },
  {
    node: {
      id: "f9523081-0d49-5f5a-8695-64f02b3bd323",
      frontmatter: {
        title: "Speaking",
        client: "RIKAI",
        company: "RIKAI Labs",
        order: 200,
        path: "/projects/speaking",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: ["RiBot", "Metrics", "Growth", "Meteor", "BizDev"],
        cover_image: "/assets/projects/speaking.jpg",
      },
      fields: {
        slug: "/projects/speaking/",
      },
    },
  },
  {
    node: {
      id: "4a8d4509-96cb-5778-aa4f-9118782107c4",
      frontmatter: {
        title: "TravelBot",
        client: "VISA",
        company: "RIKAI Labs",
        order: 200,
        path: "/projects/travelbot",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: ["Chatbots", "RiBot", "Metrics", "Growth", "MongoDB"],
        cover_image: "/assets/projects/mtl-cover.jpg",
      },
      fields: {
        slug: "/projects/travelbot/",
      },
    },
  },
  {
    node: {
      id: "158bb1e9-0658-540e-9df6-2a6cd08e2dc1",
      frontmatter: {
        title: "Comic English",
        client: "RIKAI",
        company: "RIKAI Labs",
        order: 150,
        path: "/projects/comic-english",
        sm: 3,
        xs: 6,
        where: null,
        from: null,
        until: null,
        tags: [
          "Chatbots",
          "MeteorJS",
          "Design",
          "UX-Design",
          "EdTech",
          "MachineLearning",
          "MongoDB",
          "Game-Design",
        ],
        cover_image: "/assets/projects/comic-english-cover-2.jpg",
      },
      fields: {
        slug: "/projects/comic-english/",
      },
    },
  },
  {
    node: {
      id: "31d25649-dde3-5d5c-8c71-4686f8ddf2a5",
      frontmatter: {
        title: "CamperBot",
        client: "FreeCodeCamp",
        company: "RIKAI Labs",
        order: 120,
        path: "/projects/gitterbot",
        sm: 6,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["Chatbots", "Authoring", "MiniBot", "JavaScript", "MongoDB"],
        cover_image: "/assets/projects/gitterbot.png",
      },
      fields: {
        slug: "/projects/gitterbot/",
      },
    },
  },
  {
    node: {
      id: "267aedda-2bf9-5c45-98ee-30851f5f04c0",
      frontmatter: {
        title: "Hackathons",
        client: "just for fun",
        company: "me",
        order: 120,
        path: "/projects/hackathons",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "NodeJS",
          "ReactJS",
          "WeChat",
          "MachineLearning",
          "Chatbots",
          "Socket.io",
          "TypeScript",
          "JavaScript",
          "NLP",
          "Chatbots",
        ],
        cover_image: "/assets/projects/launch-check.jpg",
      },
      fields: {
        slug: "/projects/hackathons/",
      },
    },
  },
  {
    node: {
      id: "d0c81f28-3149-5d0f-802d-debbd59159ec",
      frontmatter: {
        title: "Crystal Casters",
        client: "KLab",
        company: "KLab",
        order: 100,
        path: "/projects/casters",
        sm: 3,
        xs: 6,
        where: "tokyo and san francisco",
        from: "17/03/2019",
        until: "17/03/2019",
        tags: [
          "Unity3D",
          "Python",
          "Flask",
          "Metrics",
          "Growth",
          "PostgreSQL",
          "Game-Design",
          "Games",
        ],
        cover_image: "/assets/projects/casters-cover.png",
      },
      fields: {
        slug: "/projects/casters/",
      },
    },
  },
  {
    node: {
      id: "d3ae91bf-d517-5aec-939f-9b2483ca7cdf",
      frontmatter: {
        title: "JooJoo Japanese SNS",
        client: "Pikkle/AdWays",
        company: "Pikkle KK",
        order: 90,
        path: "/projects/joojoo",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: [
          "Ruby on Rails",
          "Flash",
          "Game-Design",
          "MySQL",
          "SNS",
          "Games",
        ],
        cover_image: "/assets/projects/joojoo-cover.jpg",
      },
      fields: {
        slug: "/projects/joojoo/",
      },
    },
  },
  {
    node: {
      id: "1e38fdd8-b3e7-5f8d-811d-8c3cc841854f",
      frontmatter: {
        title: "WonderDays",
        client: "Disney Japan",
        company: "Pikkle KK",
        order: 70,
        path: "/projects/wonder-days",
        sm: 3,
        xs: 12,
        where: null,
        from: null,
        until: null,
        tags: ["Flash", "SNS", "Game-Design", "Ruby", "Games"],
        cover_image: "/assets/projects/wd-cover.jpg",
      },
      fields: {
        slug: "/projects/wonder-days/",
      },
    },
  },
];
