import { Box, Link } from "@chakra-ui/layout";
import React from "react";
import "../styles.css";

export default function Contact() {
  return (
    <Box className="contact" backgroundColor="black" pb="100px">
      <Box textAlign="center" color="white" pt="0px">
        <Box fontWeight={500} fontSize={{ base: "20px", lg: "30px" }}>
          But I'd like to hear about what you're working on!
        </Box>
        <Box mt="20px">
          <Link
            color="#f9004d"
            target="_new"
            href="mailto:dc.web@rik.ai"
            textDecoration="none !important"
          >
            Contact me at dc.web@rik.ai
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
